"use strict";

module.exports = function () {
	
	$('.snav__link').on('click', function(e){
		var $holder = $(this).parent('.snav__item'),
			$submenu = $holder.children('.snav__sm');

		if ($submenu.length) {
			e.preventDefault();
			if (!$holder.is('.snav__item_open')) {
				$('.snav__item_open').removeClass('snav__item_open');
			}
			$holder.toggleClass('snav__item_open');
			if ($submenu.offset().left + $submenu.outerWidth() > $(window).width()) {
				$submenu.addClass('snav__sm-right');
			}
		}
	});

	$(document).on('click touchstart', function(e){
		if(!$(e.target).parents('.snav__item_open').length && $(window).width() >= 980) {
			$('.snav__item_open').removeClass('snav__item_open');
		}
	});

};