"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.portfolio')
		.append('<div class="portfolio__nav"><div class="portfolio__navInner"><div class="portfolio__prev" /><div class="portfolio__next" /></div></div>')
		.swiper({
			slidesPerView: 'auto',
			prevButton: '.portfolio__prev',
			nextButton: '.portfolio__next',
			simulateTouch: false
		});

};