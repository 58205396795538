"use strict";
require("./../../../bower_components/jquery-ui/ui/core");
require("./../../../bower_components/jquery-ui/ui/widget");
require("./../../../bower_components/jquery-ui/ui/tabs");
var sliderInit = require('./stampsSliderInit');

module.exports = function () {
	
	$('.tabs').tabs({
		activate: function(event, ui){
			ui.newPanel.children('.stamps:not(.stamps_active)').each(sliderInit);
		}
	});

};