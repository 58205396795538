"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {
	
	$('.examples__slider')
		.after('<div class="examples__prev" /><div class="examples__next" />')
		.swiper({
			slidesPerView: 'auto',
			prevButton: '.examples__prev',
			nextButton: '.examples__next',
			simulateTouch: false
		});

};