"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.thanks__slider')
		.after('<div class="thanks__prev" /><div class="thanks__next" />')
		.swiper({
			slidesPerView: 'auto',
			prevButton: '.thanks__prev',
			nextButton: '.thanks__next',
			simulateTouch: false
		});

};