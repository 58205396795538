"use strict";

module.exports = function () {
	
	$('.special__btn').on('click', function(e){
		e.preventDefault();
		$('.special__popover').toggleClass('special__popover_visible');
	});

	$(document).on('click touchstart', function(e){
		if(!$(e.target).parents('.special__more').length) {
			$('.special__popover_visible').removeClass('special__popover_visible');
		}
	});

};