"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.clients__slider')
		.after('<div class="clients__prev" /><div class="clients__next" />')
		.swiper({
			slidesPerView: 'auto',
			prevButton: '.clients__prev',
			nextButton: '.clients__next',
			simulateTouch: false
		});

};