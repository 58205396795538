"use strict";

module.exports = function () {

	$('#consult-toggle').on('click', function(e){
		var $hidden = $('#consult-hidden');

		e.preventDefault();
		if ($hidden.is(':visible')) {
			$hidden.fadeOut(150);
		} else {
			$hidden.fadeIn(150);
		}
	});

	$(document).on('click', function(e){
		if (!$(e.target).parents('.consult__form').length) {
			$('#consult-hidden').fadeOut(150);
		}
	});

};