"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	function sliderInit() {
		var $holder = $(this),
			$slider = $holder.children('.stamps__slider');

		$slider
			.after('<div class="stamps__prev" /><div class="stamps__next" />')
			.swiper({
				slidesPerView: 'auto',
				prevButton: $holder.children('.stamps__prev'),
				nextButton: $holder.children('.stamps__next'),
				simulateTouch: false
			});

		$holder.addClass('stamps_active');
	}
	
	$('.stamps:visible:not(.stamps_active)').each(sliderInit);

};