"use strict";
require('browsernizr/test/touchevents');
var Modernizr = require('browsernizr'),
    scrollbarWidth = require('./scrollbarWidth');

module.exports = function () {

    window.ppInit = function(popup) {
        var $popup = $(popup),
            $overlay = $('.overlay'),
            $visiblePopup = $('.popup_visible');

        if ($visiblePopup.length) {
            $visiblePopup.removeClass('popup_visible');
            setTimeout(function(){
                $visiblePopup.hide();
            }, 300);
        } else {
            if (!Modernizr.touchevents) {
                $('body').css({
                    'margin-right': scrollbarWidth(),
                    'overflow': 'hidden'
                });
            }
            $overlay.show(0, function(){
                $overlay.addClass('overlay_visible');
            });
        }

        if ($popup.outerHeight() > $(window).height() - 30) {
            if (Modernizr.touchevents) {
                $popup.css('top', $(document).scrollTop() + 15);
            } else {
                $popup.css('top', 15);
            }
            $popup.css('margin-top', 0);
        } else {
            if (Modernizr.touchevents) {
                $popup.css('top', $(document).scrollTop() + $(window).height()/2);
            } else {
                $popup.css('top', '50%');
            }
            $popup.css('margin-top', -$popup.outerHeight()/2);
        }

        $popup.show(0, function(){
            $popup.addClass('popup_visible');
        });
        
        return false;
    }

    $('[data-popup]').on('click', function(){
        var id = '#' + $(this).data('popup');

        if ($(this).data('popup') == 'order-example') {
            var $image = $(this).parents('.stamps__item').find('img.stamps__pic'),
                imagePath = $image.attr('src'),
                imageTitle = $image.attr('title');

            $('#order-example').find('.example__hero').html('<img src="' + imagePath + '" alt="" class="example__pic" />');
            $('#order-example').find('.example__caption').html(imageTitle);
            $('#order-example').find('#example').val(imageTitle);
        }
        ppInit(id);
        return false;
    });

    // Popup closing
    function popupClose() {
        $('.popup').removeClass('popup_visible');
        $('.overlay').removeClass('overlay_visible');
        setTimeout(function(){
            $('.popup').hide();
            $('.overlay').hide();
            $('body').css({
                'margin-right': 0,
                'overflow': 'visible'
            });
        }, 300);
    }

    $('body').on('click', '[data-popup-close]', function(){
        popupClose();
        return false;
    });

    $('.overlay').on('click', function(e){
        if(!$(e.target).parents('.overlay').length){
            popupClose();
        }
    });

    $(document).keyup(function(e) {
        if (e.keyCode == 27 && $('.popup').is('.popup_visible')) {
            popupClose();
        }
    });

};