"use strict";
require("./../../../bower_components/magnific-popup/dist/jquery.magnific-popup.js");

module.exports = function () {

	$('.thanks').magnificPopup({
		delegate: '.zoom-link',
		type: 'image',
		tLoading: 'Загрузка изображения #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1],
			arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
			tPrev: 'Назад (Стрелка влево)',
			tNext: 'Вперед (Стрелка вправо)',
			tCounter: '<span class="mfp-counter">%curr% из %total%</span>'
		},
		image: {
			tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.'
		}
	});
	
};