"use strict";

module.exports = function () {

	$('.nav-toggle').on('click', function(){
		$('.nav').toggleClass('nav_open');
	});

	$(document).on('touchstart', function(e){
		if (!$(e.target).parents('.nav').length && !$(e.target).is('.nav') && !$(e.target).parents('.nav-toggle').length && !$(e.target).is('.nav-toggle')) {
			$('.nav_open').removeClass('nav_open');
		}
	});

};