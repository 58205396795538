"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.reviews__slider')
		.append('<div class="reviews__prev" /><div class="reviews__next" />')
		.swiper({
			slidesPerView: 'auto',
			prevButton: '.reviews__prev',
			nextButton: '.reviews__next',
			simulateTouch: false
		});

};