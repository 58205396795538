"use strict";
var $ = window.jQuery = window.$ = require("./../../bower_components/jquery/dist/jquery.js");


var initCustomWidgets = function () {
	require('./modules/specialPopoverInit')();
	require('./modules/sNavInit')();
	require('./modules/fileInputInit')();
	require('./modules/stampsSliderInit')();
	require('./modules/tabsInit')();
	require('./modules/clientsSliderInit')();
	require('./modules/thanksSliderInit')();
	require('./modules/reviewsSliderInit')();
	require('./modules/portfolioSliderInit')();
	require('./modules/consultInit')();
	require('./modules/magnificInit')();
	require('./modules/phoneMaskInit')();
	require('./modules/popupInit')();
	require('./modules/navInit')();
	require('./modules/examplesSliderInit')();
};

$(initCustomWidgets);
